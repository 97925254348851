@tailwind base;

@tailwind components;

@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

/**
   Puts more breathing room between a targeted element
   and the edges of the scrollport. This allows for validations to
   scroll to properly without cutting off the input element.
   see for more info: https://css-tricks.com/almanac/properties/s/scroll-padding/#aa-ok-so-moving-on-to-scroll-padding
   120 pixels is the navbar height + the margin top on elements below it
*/
html {
  scroll-padding: 120px auto auto auto;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// Resets CSS back to browser defaults for Mass Customization
.reset {
  margin: 0;
  padding: 0;
  font-family: initial;
  text-align: start;
  background-color: transparent;
  min-height: auto;
  display: inline;
  font-size: medium;
  color: canvastext;
  overflow: visible;
  position: static;
}

// Used to prevent main page from scrolling behind overlay in mobile
.no-scroll {
  overflow: hidden;
}

@font-face {
  font-family: 'Agipo Regular';
  src: url('./fonts/AgipoRegExt.woff2') format('woff2'),
    url('./fonts/AgipoRegExt.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT America Regular';
  src: url('./fonts/GTAmerica-Regular.woff2') format('woff2'),
    url('./fonts/GTAmerica-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT America Bold';
  src: url('./fonts/GTAmerica-Bold.woff2') format('woff2'),
    url('./fonts/GTAmerica-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT America Italic';
  src: url('./fonts/GTAmerica-RegularItalic.woff2') format('woff2'),
    url('./fonts/GTAmerica-RegularItalic.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT America Regular Mono';
  src: url('./fonts/GTAmericaMono-Regular.woff2') format('woff2'),
    url('./fonts/GTAmericaMono-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./fonts/SourceSansPro-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Code Pro';
  src: url('./fonts/SourceCodePro-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter Regular';
  src: url('./fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-VariableFont.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
